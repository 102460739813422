import React, {useReducer, useContext} from 'react';
import ClientContext from './clientContext';
import ClientReducer from './clientReducer'

import axiosClient from '../../config/axios'

import AuthContext from '../../context/auth/authContext';

import {
    GET_CLIENTS,
    GET_INFO_CLIENT,
    GET_CLIENT_COMMISSIONS,
    GET_CLIENT_PROSPECTS,
    REGISTER_PROSPECTS,
    ERROR_PROSPECT,
    GET_LANGUAGES,
    GET_PHONE_CODES
} from '../../types'

const ClientState = props => {

    const { user } = useContext(AuthContext)

    const initialState = {
        clients: [],
        client: [],
        clientCommissions: [],
        prospects: [],
        register_response: false,
        message: '',
        languages: [],
        phone_codes: []
    }

    const [ state, dispatch ] = useReducer(ClientReducer, initialState);

    const getClients = (data) => {
        try {
            axiosClient({
                method: 'GET',
                url: '/get-clients',
                params: {
                    year: data.year,
                    month: data.month,
                    company: data.company,
                    status: data.statusCompany,
                    commission: data.statusCommission,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            }).then(response => {
                dispatch({
                    type:GET_CLIENTS,
                    payload:response.data
                })
            })
            .catch(error => {
                console.log(error);
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    const getInfoClient = (id) => {
        try {
            axiosClient({
                method: 'GET',
                url:`/get-client-information?company_id=${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            }).then(response => {
                dispatch({
                    type:GET_INFO_CLIENT,
                    payload:response.data
                })
            })
            .catch(error => {
                console.log(error);
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    const getClientCommission = (data) => {
        try {
            axiosClient({
                method: 'GET',
                url:`/get-client-commissions?company_id=${data.company}&year=${data.year}&month=${data.month}&carrier_name=${data.carrier}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            }).then(response => {
                dispatch({
                    type:GET_CLIENT_COMMISSIONS,
                    payload:response.data
                })
            })
            .catch(error => {
                console.log(error);
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    const getClientProspects = (data) => {
        try {
            axiosClient({
                method: 'GET',
                url:`/get-client-prospects`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            }).then(response => {
                dispatch({
                    type:GET_CLIENT_PROSPECTS,
                    payload:response.data
                })
            })
            .catch(error => {
                console.log(error);
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    const getPhoneCodes = (data) => {
        try {
            axiosClient({
                method: 'GET',
                url:`/get-phone-codes`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            }).then(response => {
                dispatch({
                    type:GET_PHONE_CODES,
                    payload:response.data
                })
            })
            .catch(error => {
                console.log(error);
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    const registerProspect = (data) => {
        try {
            axiosClient({
                method: 'POST',
                url:`/register-prospects`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                data
            }).then(response => {
                dispatch({
                    type:REGISTER_PROSPECTS,
                    payload:response.message,
                    message: 'Successfully registered prospect.'
                })
            })
            .catch(error => {
                dispatch({
                    type:ERROR_PROSPECT,
                    payload:error.response.data.message
                })
            });
            
        } catch (error) {
            dispatch({
                type:ERROR_PROSPECT,
                payload:'Ops! something was wring, try again later.'
            })
        }
    }

    const getLangs = () => {
        axiosClient({
            method: 'GET',
            url:`/config/get-languages`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        }).then(response => {
            dispatch({
                type:GET_LANGUAGES,
                payload:response.data
            })
        })
        .catch(error => {
            console.log(error);
        });
    }

    return ( 
        <ClientContext.Provider
            value={{
                clients: state.clients,
                client: state.client,
                clientCommissions: state.clientCommissions,
                prospects: state.prospects,
                register_response: state.register_response,
                message: state.message,
                languages: state.languages,
                phone_codes: state.phone_codes,
                getPhoneCodes,
                getClients,
                getInfoClient,
                getClientCommission,
                getClientProspects,
                registerProspect,
                dispatch,
                getLangs,
            }}
        >
            {props.children}
        </ClientContext.Provider>
    );
}
 
export default ClientState;
import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

//Langs
import { useTranslation } from 'react-i18next';

// Material-UI
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Tooltip from '@material-ui/core/Tooltip';

//Icons
import SearchIcon from '@material-ui/icons/Search';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PersonAdd from '@material-ui/icons/PersonAdd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//Context
import ClientContext from '../../context/client/clientContext'

//Libraries
import DatePicker from "react-datepicker";


// Component
import Menu from '../../components/layouts/Menu';
import InputIcon from '../../components/info/InputIcon';
import InputDateIcon from '../../components/info/InputDateIcon';
import SelectIcon from '../../components/info/SelectIcon';
import CustomTable from '../../components/info/CustomTable';
import CustomButton from '../../components/info/CustomButton';

//Css
import "react-datepicker/dist/react-datepicker.css";

const status = [
    {
        val: '',
        label: 'All'
    },
    {
        val: 1,
        label: 'Activada'
    },
    {
        val: 0,
        label: 'Desactivada'
    },
];

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
        borderBottom: 0,
        },
        '&:before': {
        display: 'none',
        },
        '&$expanded': {
        margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
        minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
        margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold',
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 30,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        background: '#184d9f',
        color: 'white'
    },
    divider: {
        height: 28,
        margin: 4,
    },
    hiddeResponsive: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    ShowResponsive: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
}));

const Clients = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    
    const { clients, getClients} = useContext(ClientContext);
    const [startDate, setStartDate] = useState(new Date());
    const [statusCompany, setStatusCompany] = useState('');
    const [statusCommission, setStatusCommission] = useState('');
    const [company, setCompany] = useState('');
    var timeout;
    
    const [, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    
    useEffect(() => {
        const filters = {
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1,
            statusCompany: statusCompany,
            statusCommission: statusCommission,
            company: company
        };
        getClients(filters);
    }, [startDate, statusCompany, statusCommission, company]);

    function handleTiping(event){
        clearTimeout(timeout);
        timeout = setTimeout(function(){
            clearTimeout(timeout);
            setCompany(event.target.value)
        }, 2000);
    }

    const columns = [
        { id: 'company_id', label: t('clients.table.header.id'), view: (value) => {
            return (
                <Link to={`/clients/${value}`}> <Typography style={{textAlign: 'center' ,color: '#184d9f', fontWeight: 'bold'}}>#{value}</Typography> </Link>
            )
        }},
        { id: 'company_name', label: t('clients.table.header.store'), view: (value) => {
            return (
                <Typography style={{textAlign: 'center' ,color: '#a1a1a1', fontWeight: 'bold'}}>{value}</Typography>
            )
        }},
        { id: 'shipments', label: t('clients.table.header.shipments'), view: (value) => {
            return (
                <Typography style={{textAlign: 'center' }}> {value} </Typography>
            )
        }},
        { id: 'company_status', label: t('clients.table.header.status'), view: (value) => {
            return (
                <Typography style={{textAlign: 'center', color: value ? 'green' : '#a1a1a1'}}>{value ? t('clients.table.activated') : t('clients.table.unchecked')}</Typography>
            )
        }},
        { id: 'commission_status', complement: 'commission_date', label: t('clients.table.header.status.comission'), view: (el) => {
            return (
                <Tooltip title={`${ t('label.finish.commision') }: ${ el.complement }`} >
                    <Typography
                        style={{textAlign: 'center', color: el.value ? 'green' : '#a1a1a1'}}
                    >
                        {el.value ? t('clients.table.activated') : t('clients.table.unchecked')}
                    </Typography>
                </Tooltip>
            )
        }},
        { id: 'commission', label: t('clients.table.header.grossCommission'), view: (value) => {
            return (
                <Typography style={{textAlign: 'center' }}> ${value} USD</Typography>
            )
        }},
    ];

    return (
        <Menu menuCurrent={'Clients'}>
            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <Typography variant="h4" className={classes.title}>{t('menu.clients')}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.hiddeResponsive}>
                <Grid item xs={12} sm={3}>
                    <InputIcon onChange={ handleTiping }placeholder={t('clients.form.placeholder.searchHere')} icon={<SearchIcon/>} labelText={t('clients.form.label.numberClient')}/>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <SelectIcon 
                        placeholder={t('clients.form.placeholder.searchHere')} 
                        labelText={t('clients.form.label.status')} 
                        data={status}
                        onClick = { (data) => setStatusCompany((data.target.dataset.value)) }
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <SelectIcon 
                        placeholder={t('clients.form.placeholder.searchHere')} 
                        labelText={t('clients.form.label.status.comission')} 
                        data={status}
                        onClick = { (data) => setStatusCommission((data.target.dataset.value)) }
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <DatePicker 
                        selected={startDate}
                        onChange={(date) => setStartDate(date)} 
                        customInput={<InputDateIcon placeholder={t('clients.form.placeholder.searchHere')} icon={<DateRangeIcon/>} labelText={t('clients.form.label.date')}/>}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                    />
                </Grid>
                <Grid item xs={12} sm={2} style={{alignSelf: 'flex-end', marginBottom: 10, textAlign: 'end'}}>
                    <Link to="/prospects" style={{textDecoration: 'none'}}>
                        <CustomButton startIcon={<PersonAdd/>} text={t('clients.btnAddClient')} styleButton={{borderRadius: 30, fontSize: 16}} />
                    </Link>
                </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.ShowResponsive}>
                <Grid item xs={12} sm={3} style={{alignSelf: 'flex-end', marginBottom: 10, textAlign: 'end'}}>
                    <Link to="/prospects" style={{textDecoration: 'none'}}>
                        <CustomButton startIcon={<PersonAdd/>} text={t('clients.btnAddClient')} styleButton={{borderRadius: 30, fontSize: 16}} />
                    </Link>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Accordion square onChange={handleChange('panel1')} >
                        <AccordionSummary 
                            aria-controls="Filters" 
                            id="panel1d-header" 
                            expandIcon={<ExpandMoreIcon style={{color: '#fff'}}/>}
                            style={{backgroundColor: '#0085c7', color: '#fff'}}
                        >
                            <Typography>{t('clients.filters')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                            <Grid item xs={12} sm={3}>
                                <InputIcon onChange={ handleTiping }placeholder={t('clients.form.placeholder.searchHere')} icon={<SearchIcon/>} labelText={t('clients.form.label.numberClient')}/>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <SelectIcon 
                                    placeholder={t('clients.form.placeholder.searchHere')} 
                                    labelText={t('clients.form.label.status')} 
                                    data={status}
                                    onClick = { (data) => setStatusCompany((data.target.dataset.value)) }
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <DatePicker 
                                    selected={startDate} 
                                    onChange={(date) => setStartDate(date)} 
                                    customInput={<InputDateIcon placeholder={t('clients.form.placeholder.searchHere')} icon={<DateRangeIcon/>} labelText={t('clients.form.label.date')}/>}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                />
                            </Grid>
                        </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{width: 100}}>
                    <CustomTable rows={clients} columns={columns}/>
                </Grid>
            </Grid>
        </Menu>
    )
}

export default Clients;